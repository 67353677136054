
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


















































.podcasts__intro__link {
  display: block;

  &:not(:last-child) {
    margin-bottom: $spacing * 0.5;
  }

  @include mq(s) {
    display: inline-block;

    &:not(:last-child) {
      margin-right: $spacing * 0.5;
    }
  }
}

.podcasts__intro__link__badge {
  max-height: 5rem;

  @include mq(s) {
    max-height: 4rem;
  }
}
